import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  formatDateAndTime,
  serverLink,
  showAlert,
} from "../../../resources/url";
import Header from "../../Header/header";
import SliderForm from "../slider/sliderform";

const ViewAdmissionCalender = (props) => {
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState([]);
  const getData = () => {
    axios
      .get(`${serverLink}general/admission-datelines`)
      .then((res) => {
        if (res.data.length > 0) {
          setData(res.data);
        }
      })
      .catch((error) => {
        console.log("ERROR FETCHING THE ACADEMIC CALENDER");
      });
  };

  const deleteCalender = async (id) => {
    showAlert("Warning", "Are you sure", "warning").then(async (confirm) => {
      if (confirm === true) {
        await axios
          .delete(`${serverLink}general/admission-dateline/${id}`)
          .then((res) => {
            if (res.data.message === "success") {
              getData();
              showAlert("success", "Calender deleted", "success");
            }
          });
      } else {
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Header isFaculty={false} />
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div className="toolbar" id="kt_toolbar">
          <div
            id="kt_toolbar_container"
            className="container-fluid d-flex flex-stack"
          >
            <div
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
              className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
            >
              <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                General
                <span className="h-20px border-dark  border-start ms-3 mx-2"></span>
                Academic Calender
                <span className="h-20px border-dark  border-start ms-3 mx-2"></span>
                Add Academic Calender
              </h1>
            </div>
          </div>
        </div>
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-fluid">
            {/* CONTENT START */}
            <div className="card mb-5 mb-xl-10">
              <div className="card-header">
                <div className="card-title">
                  <h3>ACADEMI CALENDER</h3>
                </div>
              </div>
              <div className="table-responsive text-start p-10 ">
                <table className="table table-hover p-10">
                  <thead>
                    <tr className="fw-bold fs-6 text-gray-800">
                      <th>Calender ID</th>
                      <th>School Semester</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Description</th>
                      <th>Inserted By</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data.map((x, i) => {
                        return (
                          <tr key={i}>
                            <td>{x.EntryID}</td>
                            <td>{x.SchoolSemester}</td>
                            <td>{formatDateAndTime(x.StartDate, "date")}</td>
                            <td>{formatDateAndTime(x.DateLine, "date")}</td>
                            <td>{x.Description}</td>
                            <td>{x.InsertedBy}</td>
                            <td>
                              <Link
                                to={`/general/admission-calender?q=${x.EntryID}`}
                                className="btn btn-primary btn-sm"
                              >
                                <i className="fa fa-eye" aria-hidden="true" />
                              </Link>
                            </td>
                            <td>
                              <button
                                type="button"
                                onClick={() => {
                                  deleteCalender(x.EntryID);
                                }}
                                className="btn btn-danger btn-sm"
                              >
                                <i className="fa fa-trash" aria-hidden="true" />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* CONTENT END */}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
  };
};
export default connect(mapStateToProps, null)(ViewAdmissionCalender);
