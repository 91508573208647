import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../Header/header";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { Oval } from "react-loader-spinner";
import axios from "axios";
import { serverLink } from "../../resources/url";
import swal from "sweetalert";

class MBSResults extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      selectedSemester: "",
      notificationStatus: "",
      clearanceStatus: "",
      allData: [],
      filteredData: [],
      selectedRows: [],
      timetableCodes: [],
      columns: [
        {
          name: "S/N",
          cell: (row, index, column, id) => {
            const position = this.state.filteredData.findIndex(
              (item) => item === row
            );
            return position + 1;
          },
          sortable: true,
        },
        {
          name: "Student ID",
          selector: (row) => `${row.StudentID}`,
          sortable: true,
        },
        {
          name: "Anatomy",
          selector: (row) => `${row.Anatomy}`,
          sortable: true,
        },
        {
          name: "Biochemistry",
          selector: (row) => `${row.Biochemistry}`,
          sortable: true,
        },
        {
          name: "Physiology",
          selector: (row) => `${row.Physiology}`,
          sortable: true,
        },
        {
          name: "Remarks",
          selector: (row) => `${row.Remarks}`,
          sortable: true,
        },
        {
          name: "Semester",
          selector: (row) => `${row.Semester}`,
          sortable: true,
        },
        {
          name: "Notification Status",
          cell: (row) => (
            <div
              className={`badge badge-light-${
                row.IsNotified ? "success" : "warning"
              } fw-bold`}
            >
              <i
                className={`fa ${
                  row.IsNotified ? "fa-check" : "fa-clock"
                } me-2`}
              ></i>
              {row.IsNotified ? "Notified" : "Pending"}
            </div>
          ),
        },
        {
          name: "Finance Status",
          cell: (row) => (
            <div
              className={`badge badge-light-${
                row.IsCleared ? "success" : "danger"
              } fw-bold`}
            >
              <i
                className={`fa ${row.IsCleared ? "fa-check" : "fa-times"} me-2`}
              ></i>
              {row.IsCleared ? "Cleared" : "Not Cleared"}
            </div>
          ),
        },
      ],
      isUploading: false,
    };
  }

  componentDidMount() {
    this.fetchMBSResults();
  }

  async fetchMBSResults() {
    try {
      const response = await axios.get(
        `${serverLink}mbs_email_process/get-students-for-notification`
      );
      this.setState({
        allData: response.data.students,
        filteredData: response.data.students,
        timetableCodes: response.data.timetableCodes,
        isLoading: false,
      });
    } catch (error) {
      this.showAlert("ERROR", "Failed to fetch MBBS results", "error");
      this.setState({ isLoading: false });
    }
  }

  handleFilterChange = (filterType, value) => {
    this.setState({ [filterType]: value }, () => {
      const { allData, selectedSemester, notificationStatus, clearanceStatus } =
        this.state;

      let filtered = [...allData];

      // Apply semester filter
      if (selectedSemester) {
        filtered = filtered.filter(
          (item) => item.Semester === selectedSemester
        );
      }

      // Apply notification status filter
      if (notificationStatus !== "") {
        filtered = filtered.filter(
          (item) => item.IsNotified === Number(notificationStatus)
        );
      }

      // Apply clearance status filter
      if (clearanceStatus !== "") {
        filtered = filtered.filter(
          (item) => item.IsCleared === Number(clearanceStatus)
        );
      }

      this.setState({ filteredData: filtered });
    });
  };

  handleSelectedRowsChange = ({ selectedRows }) => {
    this.setState({ selectedRows });
  };

  handleBatchNotification = async () => {
    const { selectedRows } = this.state;

    if (selectedRows.length === 0) {
      this.showAlert("WARNING", "Please select students to notify", "warning");
      return;
    }

    try {
      const selectedStudents = selectedRows.map((row) => row.StudentID);

      const response = await axios.post(
        `${serverLink}mbs_email_process/notify-selected-students`,
        {
          selectedStudents,
        }
      );

      const { results } = response.data;

      if (results.successCount > 0) {
        this.showAlert(
          "SUCCESS",
          `Successfully notified ${results.successCount} student(s).\n${
            results.failedCount > 0
              ? `Failed to notify ${results.failedCount} student(s).`
              : ""
          }`,
          "success"
        );

        // Refresh the data
        await this.fetchMBSResults();
      } else {
        this.showAlert(
          "WARNING",
          "No students were notified. They might have already been notified or are not cleared.",
          "warning"
        );
      }
    } catch (error) {
      this.showAlert("ERROR", "Failed to send notifications", "error");
    }
  };

  handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check file type
    const validExtensions = [".csv", ".xlsx", ".xls"];
    const isValidFile = validExtensions.some((ext) =>
      file.name.toLowerCase().endsWith(ext)
    );

    if (!isValidFile) {
      this.showAlert("ERROR", "Please upload a CSV or Excel file", "error");
      return;
    }

    try {
      // Create FormData
      const formData = new FormData();
      formData.append("resultFile", file);

      // Show loading state
      this.setState({ isUploading: true });

      const response = await axios.post(
        `${serverLink}mbs_email_process/bulk-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Show success message
      this.showAlert(
        "SUCCESS",
        `Successfully uploaded ${response.data.recordsProcessed} records`,
        "success"
      );

      // Refresh the table
      await this.fetchMBSResults();
    } catch (error) {
      this.showAlert(
        "ERROR",
        error.response?.data?.message || "Failed to upload file",
        "error"
      );
    } finally {
      this.setState({ isUploading: false });
      // Reset file input
      event.target.value = "";
    }
  };

  showAlert(title, msg, type) {
    return swal({
      title: title,
      text: msg,
      icon: type,
      button: "OK",
    });
  }

  render() {
    const { columns, filteredData } = this.state;
    const tableData = {
      columns,
      data: filteredData,
      print: false,
      exportHeaders: true,
      filterPlaceholder: "Search MBBS Results",
    };

    return (
      <>
        <Header isFaculty={false} />
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="toolbar" id="kt_toolbar">
            <div
              id="kt_toolbar_container"
              className="container-fluid d-flex flex-stack"
            >
              <div
                data-kt-swapper="true"
                data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
              >
                <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
                  MBBS Results
                  <span className="h-20px border-dark border-start ms-3 mx-2" />
                  List
                </h1>
              </div>
            </div>
          </div>

          <div className="post d-flex flex-column-fluid" id="kt_post">
            <div id="kt_content_container" className="container-fluid">
              {this.state.isLoading ? (
                <div
                  style={{
                    margin: "auto",
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "400px",
                  }}
                >
                  <Oval
                    color="#eeeeee"
                    height={50}
                    width={50}
                    secondaryColor="teal"
                    timeout={15000}
                  />
                </div>
              ) : (
                <div className="card mb-5 mb-xl-10">
                  <div className="card-header">
                    <div className="card-title">
                      <h3>MBBS RESULTS LIST</h3>
                    </div>
                    <div className="d-flex align-items-center gap-4">
                      <select
                        className="form-select form-select-solid"
                        value={this.state.selectedSemester}
                        onChange={(e) =>
                          this.handleFilterChange(
                            "selectedSemester",
                            e.target.value
                          )
                        }
                      >
                        <option value="">All Semesters</option>
                        {this.state.timetableCodes.map((code) => (
                          <option key={code} value={code}>
                            {code}
                          </option>
                        ))}
                      </select>

                      <select
                        className="form-select form-select-solid"
                        value={this.state.notificationStatus}
                        onChange={(e) =>
                          this.handleFilterChange(
                            "notificationStatus",
                            e.target.value
                          )
                        }
                      >
                        <option value="">All Notification Status</option>
                        <option value="1">Notified</option>
                        <option value="0">Not Notified</option>
                      </select>

                      <select
                        className="form-select form-select-solid"
                        value={this.state.clearanceStatus}
                        onChange={(e) =>
                          this.handleFilterChange(
                            "clearanceStatus",
                            e.target.value
                          )
                        }
                      >
                        <option value="">All Clearance Status</option>
                        <option value="1">Cleared</option>
                        <option value="0">Not Cleared</option>
                      </select>

                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="file"
                          accept=".csv,.xlsx,.xls"
                          onChange={this.handleFileUpload}
                          style={{ display: "none" }}
                          id="csvUpload"
                        />
                        <label
                          htmlFor="csvUpload"
                          className={`btn ${
                            this.state.isUploading
                              ? "btn-light-primary"
                              : "btn-light-success"
                          } d-flex align-items-center px-4`}
                        >
                          {this.state.isUploading ? (
                            <>
                              <Oval
                                height={16}
                                width={16}
                                color="#009ef7"
                                visible={true}
                                ariaLabel="uploading"
                                secondaryColor="#e1f0ff"
                                strokeWidth={4}
                                strokeWidthSecondary={4}
                              />
                              <span className="ms-2">Uploading...</span>
                            </>
                          ) : (
                            <>
                              <i className="fa fa-file-upload fs-4 me-2"></i>
                              <span className="fw-bold">Upload CSV</span>
                            </>
                          )}
                        </label>

                        <button
                          className={`btn ${
                            this.state.selectedRows.length === 0
                              ? "btn-light-primary"
                              : "btn-primary"
                          } d-flex align-items-center px-4`}
                          onClick={this.handleBatchNotification}
                          disabled={this.state.selectedRows.length === 0}
                        >
                          <i className="fa fa-paper-plane fs-4 me-2"></i>
                          <span className="fw-bold">
                            Notify Selected
                            {this.state.selectedRows.length > 0 && (
                              <span className="badge badge-light ms-2">
                                {this.state.selectedRows.length}
                              </span>
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="card-body" style={{ textAlign: "left" }}>
                    <DataTableExtensions {...tableData}>
                      <DataTable
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                        selectableRows
                        onSelectedRowsChange={this.handleSelectedRowsChange}
                      />
                    </DataTableExtensions>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
  };
};

export default connect(mapStateToProps, null)(MBSResults);
